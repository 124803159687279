<template>
  <div class="header">
    <div class="header_block">
      <HideMenu
        @hide-or-show="menuOpen"
        :isOpen="isOpen"/>
      <Logotip />
      <div class="flex local">
        <localization-page />
        <UsersBlock
          @my-profile="toMyProfile"/>
      </div>
    </div>
  </div>
</template>

<script>
import UsersBlock from '@/components/UsersBlock'
import Logotip from '@/components/Logotip'
import HideMenu from '@/components/HideMenu'
import LocalizationPage from './LocalizationPage.vue'

export default {
  name: 'ForMainLayout',
  components: { UsersBlock, Logotip, HideMenu, LocalizationPage },
  props: {
    isOpen: Boolean
  },
  methods: {
    menuOpen: function (item) {
      this.$emit('hide-or-show', item)
    },
    toMyProfile: function () {
      this.$emit('my-profile')
    }
  }
}
</script>
