<template>
  <div class="burger" @click="menuOpen">
    <img src="/icons/Burg=Filled.svg" alt="burg">
  </div>
</template>

<script>
export default {
  name: 'HideMenu',
  props: {
    isOpen: Boolean
  },
  data () {
    return {
      isOpened: this.isOpen
    }
  },
  methods: {
    menuOpen: function () {
      this.isOpened = true
      this.$emit('hide-or-show', this.isOpened)
    }
  }
}
</script>
