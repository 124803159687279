<template>
  <div class="localization" v-click-outside="hide">
      <div
        @click="switchDropdown"
        class="localization_item flex brdr_r_8 bckg_white"
        :class="[isGuest ? 'padding_0' : 'padding_t_12']">
        <img :src="flags[selectedLang]" alt="flag">
        <p class="medium_bold_m">{{language[selectedLang]}}</p>
      </div>
      <div
        v-if="isDropdown"
        class="localization_list brdr_r_8 bckg_white border_gray_2">
        <ul>
          <li
            @click="changeLang(index)"
            v-for="(lang, index) in language"
            :key="lang"
            class="flex">
            <img :src="flags[index]" alt="flag">
            <p class="medium_bold_m">{{lang}}</p>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {
  name: 'LocalizationPage',
  props: {
    isGuest: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedLang: this.isGuest ? 0 : localStorage.getItem('lang'),
      language: ['Русский', 'Қазақ', 'English'],
      langInd: ['ru', 'kz', 'en'],
      flags: ['/icons/Rus.svg', '/icons/Qazaq.svg', '/icons/Eng.svg'],
      isDropdown: false
    }
  },
  methods: {
    changeLang: function (index) {
      localStorage.setItem('lang', index)
      this.$i18n.locale = this.langInd[localStorage.getItem('lang')]
      this.selectedLang = index
      this.hide()
    },
    hide: function () {
      this.isDropdown = false
    },
    switchDropdown: function () {
      this.isDropdown = !this.isDropdown
    }
  }
}
</script>
