<template>
  <div>
    <Navbar @hide-or-show="closeMenu"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'OpenedNavbar',
  components: { Navbar },
  methods: {
    closeMenu: function (item) {
      this.$emit('hide-or-show', item)
    }
  }
}
</script>
