export default {
  ru: {
    course: 'Курсы',
    school_course: 'Курсы школы',
    my_course: 'Мои курсы',
    schedule: 'Расписание',
    user: 'Пользователи',
    analytics: 'Аналитика',
    catalog: 'Каталог',
    onboard: 'Обучение',
    info: 'Инфо',
    admin_school: 'Админ школы',
    manager: 'Менеджер',
    teacher: 'Учитель',
    student: 'Студент',
    create_course: 'Создать курс',
    filter: 'Фильтр',
    choose_category: 'Выберите категорию',
    choose_group: 'Выберите группу',
    choose_price: 'Выберите стоимость',
    search_by_text: 'Поиск по тексту',
    count_of_stud: 'Кол-во студентов',
    count_of_lessons: 'Кол-во уроков',
    students: 'студента',
    lessons: 'урока',
    course_name: 'Название курса',
    lecturer: 'Преподаватели',
    lecture: 'Преподаватель',
    the_students: 'Студенты',
    the_studentss: 'Студенты ',
    lesson: 'Уроки',
    categories: 'Категории',
    category: 'Категория',
    actions: 'Действия',
    delete: 'Удалить',
    edit: 'Редактировать',
    view: 'Посмотреть',
    my_profile: 'Мой профиль',
    exit: 'Выйти',
    back: 'Назад',
    creating_course: 'Создание курса',
    add_category: 'Добавить категорию',
    del_category: 'Удалить категорию',
    add_degree: 'Добавить степень',
    del_degree: 'Удалить степень',
    toast_del_category_success: 'Категория успешно удалена!',
    main_info: 'Основная информация',
    choose_lang_course: 'Выберите язык курса',
    instruction_add_course: 'Видео-инструкция по созданию курса',
    course_not: 'Не создано ни одного курса.',
    try_add_course: 'Самое время их создать!',
    price_of_course: 'Стоимость курса',
    price_of_study: 'Стоимость обучения',
    free_course: 'Бесплатный курс',
    category_of_course: 'Категория курса',
    display_the_course: 'Отображать курс в каталоге',
    select_teacher: 'Выберите учителей',
    close: 'Закрыть',
    search_by_teacher: 'Поиск по учителям...',
    search_by_student: 'Поиск по студентам...',
    selected_teachers: 'Выбранные учителя',
    selected_students: 'Выбранные студенты',
    details_of_course: 'Детали курса',
    short_course_desc: 'Краткое описание курса',
    full_course_desc: 'Полное описание курса',
    show_catalog_course: 'Появится в каталоге курсов',
    course_cover: 'Обложка курса',
    this_img_will_be_show_on_course_cover: 'Это изображение будет отображаться в каталоге курсов',
    path_to_file: 'Укажите путь к файлу или перенесите его в эту область.',
    not_exceed: 'Файл не должен превышать размера 30 МБ',
    select_file: 'Выбрать файл',
    media_on_page: 'Медиа на странице курса (необязательно)',
    it_can_be_photo: 'Это может быть фото, видео, демо-урок или можете прикрепить ссылку из Youtube',
    course_created_success: 'Курс успешно создан!',
    category_created_success: 'Категория успешно создана!',
    toast_degree_created_success: 'Степень успешно создана!',
    toast_degree_deleted_success: 'Степень успешно удалена!',
    course_created_error: 'Ошибка! Проверьте данные!',
    auth: 'Авторизация',
    auth_mail: 'Ваша эл. почта',
    auth_password: 'Ваш пароль',
    auth_forget_pass: 'Забыли пароль?',
    auth_enter: 'Войти',
    lang_course: 'Язык обучения',
    title_course: 'Поиск по тексту',
    cost_course: 'Стоимость',
    non_select: 'Не выбран',
    recovery_password: 'Восстановление пароля',
    recovery: 'Восстановить',
    mail_on: 'Письмо у вас на почте!',
    confirm_reset_password: 'Подтвердите сброс пароля, нажав на кнопку в письме!',
    go_back: 'Вернуться назад',
    fill_fields: 'Заполните это поле!',
    select_all: 'Выбрать все',
    remove_all: 'Убрать все',
    category_name: 'Название категории',
    degree_name: 'Степень',
    about_course: 'О курсе',
    lesson_list: 'Список уроков',
    course_analytic: 'Аналитика курса',
    edit_course: 'Редактировать курс',
    delete_course: 'Удалить курс',
    delete_lesson: 'Удалить урок',
    delete_group: 'Удалить группу',
    categories_of_course: 'Категории курса',
    features_of_course: 'Особенности курса',
    sure_remove_entry: 'Вы уверены, что хотите удалить эту запись?',
    sure_remove_work_schedule: 'Вы уверены, что хотите убрать этот график работы',
    sure_remove_lesson_schedule: 'Вы уверены, что хотите убрать этот график учебы',
    sure_remove_course: 'Вы уверены, что хотите убрать этот курс',
    sure_delete_course: 'Вы уверены, что хотите удалить этот курс',
    sure_delete_lesson: 'Вы уверены, что хотите удалить этот урок',
    sure_delete_user: 'Вы уверены, что хотите удалить пользователя из базы',
    agree_del: 'Да, удалить',
    agree_remove: 'Да, убрать',
    cancel: 'Отменить',
    all_lessons: 'Все уроки',
    create_lessons: 'Создать урок',
    vido_lesson: 'Видео урок',
    test_lesson: 'Тест',
    live_lesson: 'Live урок',
    duration: 'Длительность',
    minute: 'мин',
    preview: 'Предпросмотр',
    no_lesson: 'Нет ни одного урока.',
    add_teacher: 'Добавить преподавателя',
    add_student: 'Добавить студента',
    groups: 'Группы',
    courses_teacher: 'Преподаватели курса',
    courses_student: 'Студенты курса',
    fullname: 'Имя, Фамилия',
    mail: 'Эл. почта',
    phone: 'Телефон',
    courses_group: 'Группы курса',
    add_group: 'Создать группу',
    name_group: 'Название группы',
    edit_group: 'Редактировать группу',
    saturday: 'Суббота',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    sunday: 'Воскресенье',
    select_instructor: 'Выберите преподавателя',
    save: 'Сохранить',
    seacrh_teacher: 'Поиск по преподавателям',
    select_student: 'Выберите студента',
    search_student: 'Поиск по студентам',
    delete_teacher: 'Убрать преподавателя',
    delete_student: 'Убрать студента',
    sure_remove_teacher_from_course: 'Вы уверены, что хотите убрать преподавателя с курса',
    sure_remove_student_from_group: 'Вы уверены, что хотите убрать студента из группы',
    sure_delete_group: 'Вы уверены, что хотите удалить группу',
    create_group: 'Создание группы',
    instruction_add_group: 'Видео-инструкция по созданию группы',
    group_name: 'Название группы',
    group_teacher: 'Преподаватель группы',
    select_students: 'Выберите студентов',
    group_type: 'Тип группы',
    trial_lesson: 'Пробный урок',
    trial_lessons: 'Пробные уроки',
    indiv_lesson: 'Индивидуальный урок',
    group_lesson: 'Групповые уроки',
    pair_lesson: 'Парные уроки',
    groups_schedule: 'Расписание группы',
    no_schedule: 'Сейчас расписания нет',
    add_lesson: 'Добавить урок',
    select_lesson_format: 'Выберите формат урока',
    day_of_week: 'День недели',
    start_time: 'Время начала',
    end_time: 'Время окончания',
    all_user: 'Все пользователи',
    administration: 'Администрация',
    amount_of_course: 'Количество курсов',
    amount_of_lesson: 'Количество уроков',
    last_login: 'Последний вход (дни)',
    add_user: 'Добавить пользователя',
    role: 'Роль',
    action: 'Действия',
    reset_filter: 'Сбросить фильтр',
    del_user: 'Удалить пользователя',
    video_instruction_create_user: 'Видео-инструкция по созданию пользователя',
    creating_user: 'Создание пользователя',
    select_role: 'Выберите роль',
    create_user: 'Создать пользователя',
    name: 'Имя',
    last_name: 'Фамилия',
    password: 'Пароль',
    generate: 'Генерировать',
    email_has_already: 'Почта уже существует!',
    change_photo: 'Изменить фото',
    add_photo: 'Добавить фото',
    teachers_courses: 'Курсы преподавателя',
    work_schedule: 'График работы',
    add_course: 'Добавить курс',
    add_graph: 'Добавить график',
    select_course: 'Выберите курс',
    search_by_course: 'Поиск по курсам',
    remove_course: 'Убрать курс',
    remove_lesson_schedule: 'Убрать график учебы',
    remove_work_schedule: 'Убрать график работы',
    add_work_schedule: 'Добавить график работы',
    edit_work_schedule: 'Редактировать график работы',
    add_lesson_schedule: 'Добавить график учебы',
    edit_lesson_schedule: 'Редактировать график учебы',
    choose_suit_schedule: 'Выберите подходящий график',
    start: 'Начало',
    end: 'Окончание',
    main: 'Основные',
    communications: 'Коммуникация',
    mailing: 'Рассылка',
    comment: 'Комментарии',
    documents: 'Документы',
    document: 'Документ',
    filter_by_date: 'Фильтр по дате:',
    from: 'от',
    to: 'до',
    your_comment: 'Ваш комментарий здесь',
    add_comments: 'Добавить комментарий',
    balance: 'Баланс студента',
    students_course: 'Курсы студента',
    payment: 'Оплата',
    lessonss: 'занятий',
    add_pay: 'Добавить оплату',
    show_p_history: 'Показать историю',
    study_schedule: 'График учебы',
    lesson_format: 'Формат урока',
    lesson_type: 'Тип урока',
    select_format_lesson: 'Выберите формат урока',
    select_type_lesson: 'Выберите тип урока',
    select_schedule: 'Выберите расписание',
    add_schedule: 'Добавить расписание',
    search_payment: 'Поиск платежа по тексту',
    payment_history: 'История оплаты',
    payment_date: 'Дата оплаты',
    recommend_teacher: 'Рекомендуемые учителя ',
    video_instruction_create_lesson: 'Видео-инструкция по созданию урока',
    create_lesson: 'Создание урока',
    info_about_lesson: 'Информация об уроке',
    constructor: 'Конструктор заданий',
    name_of_lesson: 'Название урока',
    video_material: 'Видео-материал',
    select_video_display_lesson: 'Выберите видео, которое будет отображаться в уроке',
    lesson_section: 'Разделы урока',
    section_name: 'Название раздела',
    add_section: 'Добавить раздел',
    order: 'Порядок',
    create: 'Создать',
    task_test_type: 'Тестовое задание',
    task_media_type: 'Вставить медиа-файл',
    task_word_enter_type: 'Вставить слова в текст',
    task_word_enter_select_type: 'Вставить слова с вариантами',
    task_table_enter_type: 'Вставить слово в таблице',
    task_table_enter_select_type: 'Вставить варианты в таблице',
    task_find_error_type: 'Найти ошибки в тексте',
    task_text_type: 'Текстовое задание',
    task_compare_type: 'Сопоставить слова',
    task_essay_type: 'Написать эссе',
    task_file_check_type: 'Проверка файла студента',
    task_file_add_type: 'Вставить файл к уроку',
    create_task_lesson: 'Создать задание к уроку',
    task_type: 'Тип задания',
    answer_options: 'Варианты ответов',
    note_correct_answer: 'Также отметьте правильные ответы',
    immediately_show_correct_answer: 'Сразу показывать правильный ответ студенту',
    option: 'Вариант',
    add_options: 'Добавить вариант',
    save_task: 'Сохранить задание',
    correct_answer: 'Это правильный вариант',
    answer_text: 'Текст вопроса',
    add_file: 'Добавить файл',
    would_be_ppt_media: 'Это может быть презентация или видео урок',
    recommend_file: 'Рекомендации к файлу',
    img_format: 'Форматы изображения',
    video_format: 'Форматы видео',
    audio_format: 'Форматы аудио',
    file_format: 'Форматы файла',
    if_media_more: 'Если файл превышает',
    mb: 'Мб',
    media_recom: 'Изображения меньшего размера загружаются и отображаются быстрее на дисплее компьютера',
    usefull_links: 'Полезные ссылки:',
    ok: 'Хорошо',
    img_compression: 'Сжатия изображения',
    video_compression: 'Сжатие видео',
    audio_compression: 'Сжатие аудио',
    step_1_2: 'Шаг 1/2. Текст задания',
    step_2_2: 'Шаг 2/2. Выделите слова, которые нужно будет вставить',
    save_text: 'Сохранить текст',
    back_to_text: 'Вернуться к тексту',
    text_your_text: 'Напишите сюда свой текст и после сможете добавить слова для пропусков',
    add_pair: 'Создайте пары',
    create_pair: 'Добавить пару',
    field_essay: 'У студента появится поле для ввода эссе',
    field_load: 'У студента появится поле для загрузки файла',
    pin: 'Закрепить',
    name_of_task: 'Название задания',
    pin_student_file: 'Можете прикрепить файлы для скачивания студентами',
    step_1_2_table: 'Шаг 1/2. Составьте и заполните таблицу',
    strings: 'Строки',
    columns: 'Столбцы',
    error_options: 'Вариант ошибки',
    add_task: 'Добавить задание',
    mulitmedia: 'Мультимедиа',
    fill_all_gaps: 'Вам нужно заполнить все пропуски, теми словами, которые вы считаете правильными',
    essay_text: 'Текст эссе',
    your_note: 'Ваша заметка',
    delete_task: 'Удалить задание',
    sure_delete_task: 'Вы уверены, что хотите удалить это задание',
    word_1: 'Слово 1',
    word_2: 'Слово 2',
    cannot_change: 'Количество столбцов и строк менять нельзя!',
    not_found_query: 'К сожалению, по данному запросу ничего не найдено!',
    catalog_empty: 'На данный момент каталог пуст',
    edit_lesson: 'Редактирование урока',
    my_balance: 'Мой остаток',
    start_lesson: 'Начать урок',
    start_course: 'Начать курс',
    buy_course: 'Купить курс',
    course_of_lang: 'Язык курса',
    del_section: 'Удалить раздел',
    edit_section: 'Редактировать раздел',
    sure_delete_section: 'Вы уверены, что хотите удалить раздел',
    exercises_empty: 'На данный момент нет раздела для задач',
    end_lesson: 'Завершить урок',
    a_lesson: 'Урок',
    answers: 'Ответы',
    sure_finish_lesson: 'Вы уверены, что хотите завершить урок',
    agree_finish: 'Да, завершить',
    interactive_board: 'Интерактивная доска',
    back_to_tasks: 'Вернуться к заданиям',
    reset_answers: 'Сбросить ответы',
    check: 'Проверить',
    a_course: 'Курс',
    clear: 'Очистить',
    doesnt_support: 'Ваш браузер не поддерживает этот файл',
    students_answer: 'Ответы студентов',
    answer_no: 'Ответов от студентов нет',
    empty_payment_history: 'Нет истории оплаты',
    select_section: 'Выберите раздел',
    a_group: 'Группа',
    an_action: 'Действие',
    write: 'Написать',
    correct: 'правильно',
    the_tasks: 'заданий',
    show_answers: 'Показать ответы',
    hide_answers: 'Скрыть ответы',
    correct_answers: 'Правильные ответы',
    students_answers: 'Ответы студента',
    send_letter: 'Отправить письмо',
    send: 'Отправить',
    subject_mail: 'Тема письма',
    text_mail: 'Текст письма',
    date: 'дата',
    recommend: 'Рекомендуется',
    regular_lesson: 'Регулярные уроки',
    empty_document_list: 'На данный момент документов нет',
    empty_notif_list: 'На данный момент рассылки нет',
    empty_comment_list: 'На данный момент комментариев нет',
    empty_schedule_list: 'На данный момент нет графика учебы',
    today: 'Сегодня',
    week: 'неделя',
    day: 'день',
    my_settings: 'Мои настройки',
    personal_settings: 'Личные настройки',
    school_settings: 'Настройки школы',
    newsletter: 'Рассылка',
    my_tariff: 'Мой тариф',
    country: 'Страна',
    school_name: 'Название школы',
    school_desc: 'Описание школы',
    school_adress: 'Адрес школы',
    school_currency: 'Валюта школы',
    school_allowed_cancel: 'Кол-во разрешенных отмен за месяц',
    school_allowed_cancel_for: 'Отмена разрешена за',
    school_allowed_transfer: 'Кол-во разрешенных переносов за месяц',
    school_allowed_transfer_for: 'Перенос разрешен за',
    work_time: 'Рабочие часы',
    auto_lesson: 'Автоматически проводить вчерашние уроки',
    minute_f: 'минут',
    hour: 'час',
    a_hour: 'часа',
    hi_test: 'Привет, Тест!',
    notif_lesson: 'Напоминаем, что у вас урок по курсу {название курса} через в {lesson.time_from} с учителем {имя студента}',
    wish_lesson: 'Желаем провести урок с пользой',
    if_cant_notif: 'Если у вас не получается, в личном кабинете сможете перенести или отменить урок',
    with_scincerly: '– С уважением, команда “Название школы”!',
    notification_lesson: 'Напоминание об уроке',
    new_mailing: 'Новая рассылка',
    recipients: 'Получатели',
    over_period_time: 'За период времени',
    all: 'Все',
    text_mailing: 'Текст рассылки',
    trial: 'Пробный',
    indiv: 'Индивидуальный',
    group: 'Групповой',
    pair: 'Парный',
    change: 'Изменить',
    add_mailing: 'Добавить рассылку',
    delete_notif: 'Удалить напоминание',
    sure_notif_delete: 'Вы уверены, что хотите удалить напоминание',
    update_tariff: 'Обновить тариф',
    payment_a_history: 'История платежей',
    active: 'Активен',
    no_active: 'Не активен',
    monthly_payment: 'Ежемесячный платеж',
    number_students: 'Количество студентов',
    next_payment: 'Следующий платеж',
    number_months: 'Количество месяцев',
    total: 'Итого:',
    pay: 'Оплатить',
    sum: 'Сумма',
    transactions: '№ транзакции',
    description: 'Описание',
    lesson_done: 'Урок проведен',
    reschedule_lesson: 'Перенести урок',
    cancel_lesson: 'Отменить урок',
    return: 'Вернуть',
    filter_by_schedule: 'Фильтр по графику',
    by_lesson: 'По урокам',
    by_students: 'По студентам',
    empty_analytic: 'На данный момент аналитика пуста.',
    empty_analytic_1: 'Начните развивать свою школу, чтобы аналитика появилась',
    graphs: 'Графики',
    report: 'Отчет',
    jan: 'ЯНВ',
    feb: 'ФЕВ',
    mar: 'МАР',
    apr: 'АПР',
    may: 'МАЙ',
    jun: 'ИЮН',
    jul: 'ИЮЛ',
    avg: 'АВГ',
    sen: 'СЕН',
    okt: 'ОКТ',
    nov: 'НОЯ',
    dec: 'ДЕК',
    select_by_course: 'Выбор по курсу',
    amount_of_purchase: 'Количество покупок',
    sum_of_sales: 'Сумма продаж',
    search__student: 'Поиск студента',
    search__teacher: 'Поиск преподавателя',
    more: 'Подробнее',
    purchases: 'Покупки',
    selling: 'Продажа',
    done: 'Сделано',
    progress: 'Прогресс',
    current_month: 'Текущий месяц',
    conducted_lesson: 'Проведенные уроки',
    canceled_lesson: 'Отмененные уроки',
    transfered_lesson: 'Перенесенные уроки',
    statistic_type_lesson: 'Статистика по типу уроков',
    individual_lesson: 'Индивидуальные уроки',
    conducted: 'Проведено',
    canceled: 'Отменен',
    postponed: 'Перенесено',
    implementation: 'Реализация',
    select_by_student: 'Выбор по студенту',
    a_lessons: 'урок',
    attendance_of_lessons: 'Посещение уроков',
    effectiveness_of_lessons: 'Эффективность уроков',
    total_lessons: 'Всего уроков',
    exercises: 'Упражнения',
    a_correct: 'Правильно',
    incorrect: 'Неправильно',
    correct_done: 'Правильно / Сделано',
    all_right_reserved: 'Все права защищены',
    personal_data: 'Обработка персональных данных',
    confidentiality: 'Конфиденциальность',
    terms_of_use: 'Пользовательское соглашение',
    online_payments_security: 'Безопасность онлайн платежей',
    open: 'Открыть',
    for_questions: 'По вопросам',
    fill_category_name: 'Заполните название категории',
    fill_course_name: 'Заполните название курса',
    fill_category: 'Выберите категорию!',
    fill_teacher: 'Выберите преподавателя!',
    fill_short_desc: 'Заполните краткое описание курса!',
    fill_full_desc: 'Заполните полное описание курса!',
    fill_poster: 'Выберите обложку курса!',
    course_update_media_upload: 'Курс успешно обновлен! Подождите, медиа файл грузится!',
    media_uploaded: 'Медиа файл успешно загрузился!',
    fill_media: 'Выберите медиа файл!',
    fill_lesson_name: 'Заполните название урока!',
    lesson_created_success: 'Урок успешно создан!',
    fill_duration: 'Заполните длительность!',
    fill_order: 'Заполните порядок!',
    lesson_edited_success: 'Урок успешно обновлен!',
    fill_name: 'Заполните имя!',
    fill_last_name: 'Заполните фамилию!',
    fill_phone: 'Заполните телефон!',
    profile_data_update: 'Персональные данные пользователя обновлены!',
    avatar_apdate: 'Аватар обновлен!',
    fill_school_name: 'Заполните название школы!',
    fill_adress_name: 'Заполните адрес школы!',
    fill_allow_cancel: 'Укажите количество разрешенных отмен за месяц!',
    fill_allow_transfer: 'Укажите количество разрешенных переносов за месяц!',
    success_school_name: 'Данные школы обновлены!',
    fill_email: 'Заполните эл.почту!',
    fill_password: 'Заполните пароль!',
    user_created_success: 'Пользователь успешно создан!',
    user_updated_success: 'Пользователь успешно обновлен!',
    user_deleted_success: 'Пользователь успешно удален!',
    toast_work_schedule: 'График работы добавлен!',
    toast_work_schedule_edit: 'График работы обновлен!',
    fill_course: 'Выберите курс!',
    fill_price: 'Укажите стоимость!',
    fill_count_lesson: 'Укажите количество уроков!',
    toast_course_add: 'Курс добавлен!',
    toast_course_edit: 'Данные баланса пользователя обновлены!',
    toast_study_schedule: 'График учебы добавлен!',
    toast_study_schedule_edit: 'График учебы обновлен!',
    toast_format: 'Выберите формат урока!',
    toast_type: 'Выберите тип урока!',
    toast_group_name: 'Заполните название группы!',
    toast_students: 'Выберите студентов!',
    toast_group_create: 'Группа успешно создана!',
    toast_group_update: 'Группа успешно обнавлена!',
    toast_section_name: 'Заполните название раздела!',
    toast_section_order: 'Заполните порядок раздела!',
    toast_section_add: 'Раздел добавлен!',
    toast_section_name_edit: 'Название раздела изменен!',
    toast_section_delete: 'Раздел успешно удален!',
    toast_test_task: 'Заполните название задания!',
    toast_test_task_answer: 'Заполните текст вопроса!',
    toast_test_task_options: 'Заполните текст вариантов!',
    toast_test_task_options_add: 'Добавьте вариант!',
    toast_test_task_options_correct: 'Выберите правильный вариант!',
    toast_task_added: 'Задание успешно добавлено!',
    toast_task_table_r_c: 'Укажите количество столбцов и строк!',
    toast_test_task_text: 'Заполните текст задания!',
    toast_task_connect_add: 'Добавьте пару!',
    toast_task_connect_word: 'Заполните слова!',
    task_updated_success: 'Задание успешно обновлено!',
    something_went_wrong: 'Что-то пошло не так, попробуйте снова...',
    toast_notif_add: 'Рассылка успешно добавлена!',
    toast_notif_delete: 'Рассылка успешно удалена!',
    toast_comment_added: 'Комментарий успешно добавлен!',
    toast_comment_field: 'Заполните поле комментарий!',
    toast_avail_course_added: 'Курс успешно добавлен!',
    toast_avail_course_delete: 'Курс успешно удален!',
    toast_lesson_schedule_delete: 'График учебы успешно удалено!',
    toast_work_schedule_delete: 'График работы успешно удален!',
    toast_lesson_delete: 'Урок успешно удален!',
    toast_teacher_course_remove: 'Преподаватель успешно убран из списка!',
    toast_teacher_course_added: 'Преподаватель успешно добавлен в список!',
    toast_student_course_remove: 'Студент успешно убран из списка!',
    toast_student_course_added: 'Студент успешно добавлен в список!',
    empty_course: 'На данный момент нет курса.',
    my_group: 'Мои группы',
    my_groups_student: 'Студенты моей группы',
    search: 'Поиск',
    no_matches: 'Нет совпадений',
    kazakh: 'Казахский',
    rus: 'Русский',
    eng: 'Английский',
    turk: 'Турецкий',
    nem: 'Немецкий',
    venger: 'Венгерский',
    copy: 'Копировть курс',
    toast_copy_success: 'Курс успешно скопирован!',
    select_degree: 'Выберите степень',
    select_range_data: 'Выберите диапазон дат',
    offer: 'Оферта',
    i_have_acc: 'У меня есть аккаунт',
    account_create: 'Аккаунт создан',
    registrate: 'Регистрация',
    italy: 'Итальянский',
    terms: 'Сроки',
    del_format: 'Удалить формат',
    add_format: 'Добавить формат',
    fill_format: 'Заполните название формата',
    name_format: 'Название формата',
    course_format: 'Формат курса',
    toast_format_created_success: 'Формат успешно создан!',
    toast_format_deleted_success: 'Формат успешно удален!',
    format: 'Формат',
    fill_starte_date: 'Заполните сроки!',
    poster_url: 'Ссылка на видео в Youtube',
    link_youtube: 'Прикрепить ссылку видео из Youtube',
    deadlines_for_submission: 'Сроки подачи',
    poster_media: 'Выбрать свой медиафайл',
    how_it_works: 'Как работает Grafen?',
    text_onboard: 'Пройдите небольшое обучение и пользуйтесь платформой легко и просто!',
    later: 'Позже'
  },
  kz: {
    course: 'Курстар',
    school_course: 'Мектептің курстары',
    my_course: 'Менің курстарым',
    schedule: 'Кесте',
    user: 'Қолданушылар',
    analytics: 'Аналитика',
    catalog: 'Каталог',
    onboard: 'Оқу',
    info: 'Ақпарат',
    admin_school: 'Мектеп админі',
    manager: 'Менеджер',
    teacher: 'Мұғалім',
    student: 'Студент',
    create_course: 'Курс қосу',
    filter: 'Фильтр',
    choose_category: 'Категорияны таңдау',
    choose_price: 'Бағаны таңдау',
    search_by_text: 'Текст іздеу',
    count_of_stud: 'Студенттер саны',
    count_of_lessons: 'Сабақтар саны',
    students: 'студент',
    lessons: 'сабақ',
    course_name: 'Курс аты',
    lecturer: 'Мұғалімдер',
    lecture: 'Мұғалім',
    the_students: 'Студенттер',
    the_studentss: 'Студенттер ',
    lesson: 'Сабақтар',
    categories: 'Категориялар',
    category: 'Категория',
    actions: 'Іс-әрекеттер',
    delete: 'Өшіру',
    edit: 'Өңдеу',
    view: 'Көру',
    my_profile: 'Менің профилім',
    exit: 'Шығу',
    back: 'Артқа',
    creating_course: 'Курс құру',
    add_category: 'Категория қосу',
    del_category: 'Категория өшіру',
    add_degree: 'Дәреже қосу',
    del_degree: 'Дәреже өшіру',
    main_info: 'Негізгі ақпарат',
    choose_lang_course: 'Курстың тілін таңдаңыз',
    instruction_add_course: 'Курс құруға арналған бейне нұсқаулық',
    course_not: 'Ешқандай курс құрылмады.',
    try_add_course: 'Оларды құрудың уақыты келді!',
    price_of_course: 'Курстың бағасы',
    price_of_study: 'Оқу бағасы',
    free_course: 'Тегін курс',
    category_of_course: 'Курстың категориясы',
    display_the_course: 'Курсты каталогта көрсету',
    select_teacher: 'Мұғалімді таңдаңыз',
    close: 'Жабу',
    search_by_teacher: 'Мұғалім бойынша іздеу...',
    search_by_student: 'Студент бойынша іздеу...',
    selected_teachers: 'Таңдалған мұғалімдер',
    selected_students: 'Таңдалған студенттер',
    details_of_course: 'Курс бойынша мәліметтер',
    short_course_desc: 'Курстың қысқаша сипаттамасы',
    full_course_desc: 'Курстың толық сипаттамасы',
    show_catalog_course: 'Курс каталогында көрінеді',
    course_cover: 'Курстың мұқабасы',
    this_img_will_be_show_on_course_cover: 'Бұл мұқаба курс каталогында көрсетіледі',
    path_to_file: 'Файлға жолды көрсетіңіз немесе оны осы аймаққа сүйреңіз.',
    not_exceed: 'Файл 30 МБ аспауы керек',
    select_file: 'Файл таңдаңыз',
    media_on_page: 'Курс бетіндегі медиа (міндетті емес)',
    it_can_be_photo: 'Бұл фото, видео, демонстрациялық сабақ болуы мүмкін немесе Youtube сілтемені тіркей аласыз',
    course_created_success: 'Курс сәтті құрылды!',
    category_created_success: 'Категория сәтті құрылды!',
    course_created_error: 'Қате! Ақпаратты тексеріңіз!',
    auth: 'Авторизация',
    auth_mail: 'Сіздің эл. поштаңыз',
    auth_password: 'Сіздің құпиясөзіңіз',
    auth_forget_pass: 'Құпия сөзіңізді ұмыттыңыз ба?',
    auth_enter: 'Кіру',
    lang_course: 'Оқыту тілі',
    title_course: 'Текст бойынша іздеу',
    cost_course: 'Бағасы',
    recovery_password: 'Құпия сөзді қалпына келтіру',
    recovery: 'Қалпына келтіру',
    mail_on: 'Хат сіздің поштаңызда!',
    confirm_reset_password: 'Электрондық поштадағы батырманы басу арқылы құпия сөзді қалпына келтіруді растаңыз!',
    go_back: 'Артқа қайту',
    fill_fields: 'Осы өрісті толтырыңыз',
    select_all: 'Барлығын таңдау',
    remove_all: 'Барлығын алып тастау',
    category_name: 'Категория аты',
    about_course: 'Курс туралы',
    lesson_list: 'Сабақтар тізімі',
    course_analytic: 'Курстың аналитикасы',
    edit_course: 'Курсты өңдеу',
    delete_course: 'Курсты өшіру',
    delete_lesson: 'Сабақты өшіру',
    delete_group: 'Топты өшіру',
    categories_of_course: 'Курс категориялары',
    features_of_course: 'Курс ерекшеліктері',
    sure_remove_entry: 'Бұл жазбаны шынымен жойғыңыз келе ме?',
    sure_remove_lesson_schedule: 'Бұл сабақ кестесін алып тастағыңыз келетініне сенімдісіз бе',
    sure_remove_work_schedule: 'Бұл жұмыс кестесін алып тастағыңыз келетініне сенімдісіз бе',
    sure_remove_course: 'Бұл курсты алып тастағыңыз келетініне сенімдісіз бе',
    sure_delete_course: 'Бұл курсты өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_lesson: 'Бұл сабақты өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_group: 'Бұл топты өшіргіңіз келетініне сенімдісіз бе',
    sure_delete_user: 'Бұл қолданушыны өшіргіңіз келетініне сенімдісіз бе',
    agree_del: 'Иә, өшіру',
    cancel: 'Болдырмау',
    all_lessons: 'Барлық сабақтар',
    create_lessons: 'Сабақ құру',
    vido_lesson: 'Бейне сабақ',
    test_lesson: 'Тест',
    live_lesson: 'Live сабақ',
    duration: 'Ұзақтығы',
    minute: 'мин',
    preview: 'Алдын ала көру',
    no_lesson: 'Сабақ жоқ.',
    add_teacher: 'Мұғалімді қосу',
    add_student: 'Студентті қосу',
    groups: 'Топтар',
    courses_teacher: 'Курс мұғалімдері',
    courses_student: 'Курс студенттері',
    fullname: 'Аты-жөні',
    mail: 'Эл. пошта',
    phone: 'Телефон',
    courses_group: 'Курс топтары',
    add_group: 'Топ құру',
    name_group: 'Топ аты',
    edit_group: 'Топты өңдеу',
    saturday: 'Сенбі',
    monday: 'Дүйсенбі',
    tuesday: 'Сейсенбі',
    wednesday: 'Сәрсенбі',
    thursday: 'Бейсенбі',
    friday: 'Жұма',
    sunday: 'Жексенбі',
    select_instructor: 'Мұғалімді таңдаңыз',
    save: 'Сақтау',
    seacrh_teacher: 'Мұғалімді іздеу',
    select_student: 'Студентті таңдаңыз',
    search_student: 'Студентті іздеу',
    delete_teacher: 'Мұғалімді өшіру',
    delete_student: 'Студентті өшіру',
    sure_remove_teacher_from_course: 'Мұғалімді шығаруға сенімдісіз бе курстан',
    agree_remove: 'Иә, алып тастау',
    sure_remove_student_from_group: 'Студентті шығаруға сенімдісіз бе группадан',
    create_group: 'Топ құру',
    instruction_add_group: 'Топ құруға арналған бейне нұсқау',
    group_name: 'Топ аты',
    group_teacher: 'Топ мұғалімі',
    select_students: 'Студентті таңдау',
    group_type: 'Группаның типі',
    trial_lesson: 'Сынақ сабағы',
    indiv_lesson: 'Жеке сабақ',
    group_lesson: 'Топтық сабақтар',
    pair_lesson: 'Жұптастырылған сабақ',
    groups_schedule: 'Топтық жұмыс кестесі',
    no_schedule: 'Қазіргі уақытта кесте жоқ',
    add_lesson: 'Сабақ қосу',
    select_lesson_format: 'Сабақ форматын таңдаңыз',
    day_of_week: 'Апта күні',
    start_time: 'Басталу уақыты',
    end_time: 'Аяқталу уақыты',
    amount_of_course: 'Курс саны',
    amount_of_lesson: 'Сабақ саны',
    last_login: 'Соңғы кіру уақыты (күндер)',
    add_user: 'Пайдаланушы қосу',
    role: 'Рөл',
    action: 'Іс-әрекет',
    reset_filter: 'Фильтрді тазалау',
    del_user: 'Қолданушыны өшіру',
    video_instruction_create_user: 'Қолданушыны құруға арналған бейне нұсқаулық',
    creating_user: 'Қолданушыны құру',
    select_role: 'Рөлді таңдаңыз',
    create_user: 'Қолданушыны құру',
    name: 'Аты',
    last_name: 'Тегі',
    password: 'Құпиясөз',
    generate: 'Генерация',
    email_has_already: 'Пошта базада бар!',
    change_photo: 'Фото өзгерту',
    teachers_courses: 'Мұғалімнің курстары',
    work_schedule: 'Жұмыс кестесі',
    add_course: 'Курс қосу',
    add_graph: 'График қосу',
    select_course: 'Курсты таңдаңыз',
    search_by_course: 'Курсты іздеу',
    remove_course: 'Курсты алып тастау',
    add_work_schedule: 'Жұмыс кестесін қосыңыз',
    add_lesson_schedule: 'Сабақ кестесін қосыңыз',
    choose_suit_schedule: 'Дұрыс күн тәртібін таңдаңыз',
    start: 'Бастау',
    end: 'Аяқталу',
    remove_work_schedule: 'Жұмыс кестесін алып тастау',
    main: 'Негізгі',
    communications: 'Байланыс',
    mailing: 'Рассылкалар',
    comment: 'Пікірлер',
    documents: 'Құжаттар',
    document: 'Құжат',
    filter_by_date: 'Күні бойынша фильтр:',
    from: 'бастап',
    to: 'дейін',
    your_comment: 'Сіздің пікіріңіз осында',
    add_comments: 'Пікір қосу',
    balance: 'Студент балансы',
    students_course: 'Студент курсы',
    payment: 'Төлем',
    lessonss: 'сабақ',
    add_pay: 'Төлем қосу',
    show_p_history: 'Төлемдерді көрсету',
    study_schedule: 'Оқу кестесі',
    remove_lesson_schedule: 'Сабақ кестесін алып тастау',
    lesson_format: 'Сабақтың форматы',
    lesson_type: 'Сабақтың типі',
    select_format_lesson: 'Сабақтың форматын таңдаңыз',
    select_type_lesson: 'Сабақтың типін таңдаңыз',
    select_schedule: 'Оқу кестесін таңдаңыз',
    add_schedule: 'Оқу кестесін қосу',
    search_payment: 'Мәтін бойынша төлемді іздеңіз',
    payment_history: 'Төлем тарихы',
    payment_date: 'Төлем күні',
    recommend_teacher: 'Ұсынылатын мұғалімдер',
    video_instruction_create_lesson: 'Сабақты құруға арналған бейне нұсқаулық',
    create_lesson: 'Сабақ құру',
    info_about_lesson: 'Сабақ туралы ақпарат',
    constructor: 'Тапсырма конструкторы',
    name_of_lesson: 'Сабақтың атауы',
    video_material: 'Бейне материал',
    select_video_display_lesson: 'Сабақта көрсетілетін бейнені таңдаңыз',
    lesson_section: 'Сабақтың бөлімдері',
    section_name: 'Бөлім атауы',
    add_section: 'Бөлім қосу',
    order: 'Реті',
    create: 'Құру',
    task_test_type: 'Сынама',
    task_media_type: 'Медиа файлды салу',
    task_word_enter_type: 'Сөздерді мәтінге салу',
    task_word_enter_select_type: 'Нұсқалармен сөздерді салыңыз',
    task_table_enter_type: 'Кестеге сөзді салу',
    task_table_enter_select_type: 'Кестеге нұсқаларды салу',
    task_find_error_type: 'Мәтіндегі қателерді табу',
    task_text_type: 'Мәтіндік тапсырма',
    task_compare_type: 'Сөздерді салыстыр',
    task_essay_type: 'Эссе жазу',
    task_file_check_type: 'Студенттік файлдарды тексеру',
    task_file_add_type: 'Файлды сабаққа  тіркеу',
    create_task_lesson: 'Сабаққа тапсырма қосу',
    task_type: 'Тапсырма типі',
    answer_options: 'Жауап нұсқалары',
    note_correct_answer: 'Дұрыс жауаптарды белгілеңіз',
    immediately_show_correct_answer: 'Студентке дұрыс жауап көрсету',
    option: 'Нұсқа',
    add_options: 'Нұсқа қосу',
    save_task: 'Тапсырма сақтау',
    correct_answer: 'Бұл дұрыс нұсқа',
    answer_text: 'Сұрақтың тексті',
    add_file: 'Файлды қосу',
    would_be_ppt_media: 'Бұл презентация немесе бейне сабақ болуы мүмкін',
    recommend_file: 'Файл бойынша ұсыныстар',
    img_format: 'Сурет форматтары',
    video_format: 'Бейне форматтары',
    audio_format: 'Аудио форматтары',
    file_format: 'Файл форматтары',
    if_media_more: 'Егер файл өлшемінен үлкен болса',
    mb: 'Мб',
    media_recom: 'Кішірек кескіндер компьютер дисплейінде жылдам жүктеледі және көрсетіледі',
    usefull_links: 'Пайдалы сілтемелер:',
    ok: 'Жақсы',
    img_compression: 'Кескінді қысу',
    video_compression: 'Бейнені қысу',
    audio_compression: 'Аудионы қысу',
    step_1_2: '1/2 қадам. Тапсырма мәтіні',
    step_2_2: '2/2 қадам. Енгізілетін сөздерді белгілеңіз',
    save_text: 'Мәтінді сақтау',
    back_to_text: 'Мәтінге оралу',
    text_your_text: 'Мәтініңізді осы жерге жазыңыз, содан кейін бос орындарға сөздер қосуға болады',
    add_pair: 'Жұптарды құру',
    create_pair: 'Жұптарды қосу',
    field_essay: 'Студенттe эссе жазу өрісі болады',
    field_load: 'Студенттe файлды жүктеп салу өрісі болады',
    pin: 'Бекіту',
    name_of_task: 'Тапсырма атауы',
    pin_student_file: 'Сіз студенттерге жүктеп алу үшін файлдарды қоса аласыз',
    step_1_2_table: '1/2 қадам. Кестені жасаңыз және толтырыңыз',
    strings: 'Жолдар',
    columns: 'Бағандар',
    error_options: 'Нұсқа қатесі',
    add_task: 'Тапсырма қосу',
    mulitmedia: 'Мультимедиа',
    fill_all_gaps: 'Барлық бос орындарды дұрыс деп санайтын сөздермен толтыру керек',
    essay_text: 'Эссе мәтіні',
    your_note: 'Сіздің жазбаңыз',
    delete_task: 'Тапсырманы жою',
    sure_delete_task: 'Бұл тапсырманы шынымен жойғыңыз келе ме',
    word_1: '1-сөз',
    word_2: '2-сөз',
    cannot_change: 'Бағандар мен жолдар санын өзгертуге болмайды!',
    not_found_query: 'Кешіріңіз, сіздің іздеуіңіз бойынша нәтижелер табылмады!',
    catalog_empty: 'Каталог қазіргі уақытта бос',
    edit_lesson: 'Сабақты өңдеу',
    my_balance: 'Менің қалдығым',
    start_lesson: 'Сабақты бастау',
    start_course: 'Курсты бастау',
    buy_course: 'Сатып алу',
    course_of_lang: 'Курс тілі',
    del_section: 'Бөлімді өшіру',
    edit_section: 'Бөлімді өңдеу',
    sure_delete_section: 'Бөлімді өшіргіңіз келетініне сенімдісіз бе',
    exercises_empty: 'Қазіргі уақытта тапсырмаларға арналған бөлім жоқ',
    end_lesson: 'Сабақты аяқтау',
    a_lesson: 'Сабақ',
    answers: 'Жауаптар',
    sure_finish_lesson: 'Сабақты аяқтағыңыз келетініне сенімдісіз бе',
    agree_finish: 'Иә, аяқталды',
    interactive_board: 'Интерактивті тақта',
    back_to_tasks: 'Тапсырмаларға оралу',
    edit_work_schedule: 'Жұмыс кестесін өңдеу',
    reset_answers: 'Жауаптарды ысыру',
    check: 'Тексеру',
    a_course: 'Курс',
    clear: 'Тазалау',
    doesnt_support: 'Сіздің браузеріңіз бұл файлды қолдамайды',
    students_answer: 'Студенттердің жауаптары',
    answer_no: 'Студенттердің жауабы жоқ',
    choose_group: 'Топты таңдаңыз',
    empty_payment_history: 'Төлем тарихы жоқ',
    select_section: 'Бөлімді таңдаңыз',
    a_group: 'Топ',
    an_action: 'Әрекет',
    write: 'Жазу',
    correct: 'дұрыс',
    the_tasks: 'тапсырма',
    show_answers: 'Жауаптарды көрсету',
    hide_answers: 'Жауаптарды жасыру',
    correct_answers: 'Дұрыс жауаптар',
    students_answers: 'Студент жауаптары',
    send_letter: 'Хат жіберу',
    send: 'Жіберу',
    subject_mail: 'Хат тақырыбы',
    text_mail: 'Хат тексті',
    date: 'дата',
    recommend: 'Ұсынылады',
    regular_lesson: 'Тұрақты сабақтар',
    trial_lessons: 'Сынақ сабақтары',
    empty_document_list: 'Қазіргі уақытта құжаттар жоқ',
    empty_comment_list: 'Қазіргі уақытта пікірлер жоқ',
    empty_schedule_list: 'Қазіргі уақытта оқу кестесі жоқ',
    edit_lesson_schedule: 'Оқу кестесін өңдеңіз',
    today: 'Бүгін',
    week: 'апта',
    day: 'күн',
    my_settings: 'Менің параметрлерім',
    personal_settings: 'Жеке параметрлер',
    school_settings: 'Мектеп параметрлері',
    newsletter: 'Рассылка',
    my_tariff: 'Менің тарифім',
    country: 'Мемлекет',
    school_name: 'Мектеп аты',
    school_desc: 'Мектеп сипаттамасы',
    school_adress: 'Мектеп мекенжайы',
    school_currency: 'Мектеп валютасы',
    school_allowed_cancel: 'Айына рұқсат етілген бас тартулар саны',
    school_allowed_cancel_for: 'Бас тартуға рұқсат етілген',
    work_time: 'Жұмыс сағаттары',
    auto_lesson: 'Кешегі сабақтарды автоматты түрде өткізу',
    minute_f: 'минут',
    hour: 'сағат',
    a_hour: 'сағат',
    school_allowed_transfer: 'Айына рұқсат етілген аударымдар саны',
    school_allowed_transfer_for: 'Ауыстыруға рұқсат етілген',
    hi_test: 'Сәлем, Тест!',
    notif_lesson: 'Сізде {курс аты} курсы бойынша {lesson.time_from} {студент аты} мұғалімімен сабақ бар екенін еске саламыз',
    wish_lesson: 'Сабақтан ләззат алуларыңызға тілектеспіз',
    if_cant_notif: 'Сәтсіз болса, жеке кабинетте сабақтың уақытын өзгертуге немесе одан бас тартуға болады',
    with_scincerly: '– Құрметпен, {мектеп аты} командасы!',
    notification_lesson: 'Сабақты еске түсіру',
    new_mailing: 'Жаңа ақпаратты жіберу',
    recipients: 'Алушылар',
    over_period_time: 'Белгілі бір уақыт аралығында',
    all: 'Барлық',
    text_mailing: 'Рассылка мәтіні',
    trial: 'Сынақ',
    indiv: 'Жеке',
    group: 'Топ',
    pair: 'Жұп',
    change: 'Өзгерту',
    add_mailing: 'Рассылка қосу',
    delete_notif: 'Еске салғышты жою',
    sure_notif_delete: 'Еске салғышты жойғыңыз келетініне сенімдісіз бе',
    update_tariff: 'Тариф жаңарту',
    payment_a_history: 'Төлемдер тарихы',
    active: 'Белсенді',
    no_active: 'Белсенді емес',
    monthly_payment: 'Ай сайынғы төлем',
    number_students: 'Студенттер саны',
    next_payment: 'Келесі төлем',
    number_months: 'Айлар саны',
    total: 'Барлығы:',
    pay: 'Төлеу',
    sum: 'Сумма',
    transactions: 'Транзакции №',
    description: 'Сипаттама',
    add_photo: 'Фото қосу',
    lesson_done: 'Сабақ аяқталды',
    reschedule_lesson: 'Сабақты қайта жоспарлау',
    cancel_lesson: 'Сабақтан бас тарту',
    return: 'Қайтару',
    filter_by_schedule: 'Кесте бойынша фильтр',
    by_lesson: 'Сабақтар бойынша',
    by_students: 'Студенттер бойынша',
    empty_analytic: 'Қазіргі уақытта аналитика бос.',
    empty_analytic_1: 'Аналитика пайда болуы үшін мектебіңізді дамытуды бастаңыз',
    graphs: 'Графиктер',
    report: 'Баяндама',
    jan: 'ҚАҢ',
    feb: 'АҚП',
    mar: 'НАУ',
    apr: 'СӘУ',
    may: 'МАМ',
    jun: 'МАУ',
    jul: 'ШІЛ',
    avg: 'ТАМ',
    sen: 'ҚЫР',
    okt: 'ҚАЗ',
    nov: 'ҚАР',
    dec: 'ЖЕЛ',
    select_by_course: 'Курс бойынша таңдау',
    amount_of_purchase: 'Сатып алулар саны',
    sum_of_sales: 'Сату сомасы',
    search__student: 'Студентті іздеу',
    search__teacher: 'Мұғалімді іздеу',
    more: 'Толығырақ',
    purchases: 'Сатып алулар',
    selling: 'Сатылым',
    done: 'Жасалды',
    progress: 'Прогресс',
    current_month: 'Ағымдағы ай',
    conducted_lesson: 'Өткізілген сабақтар',
    canceled_lesson: 'Жойылған сабақтар',
    transfered_lesson: 'Ауыстырылған сабақтар',
    statistic_type_lesson: 'Сабақтың түрі бойынша статистика',
    individual_lesson: 'Жеке сабақтар',
    conducted: 'Өткізілген',
    canceled: 'Бас тартылды',
    postponed: 'Кейінге қалдырылды',
    implementation: 'Іске асыру',
    select_by_student: 'Студентті таңдау',
    a_lessons: 'сабақ',
    attendance_of_lessons: 'Сабаққа қатысу',
    effectiveness_of_lessons: 'Сабақтың тиімділігі',
    total_lessons: 'Жалпы сабақтар',
    exercises: 'Жаттығулар',
    a_correct: 'Дұрыс',
    incorrect: 'Қате',
    correct_done: 'Дұрыс / Жасалды',
    all_right_reserved: 'Барлық құқықтар қорғалған',
    personal_data: 'Жеке деректерді өңдеу',
    confidentiality: 'Құпиялылық',
    terms_of_use: 'Пайдалану шарттары',
    online_payments_security: 'Онлайн төлем қауіпсіздігі',
    open: 'Ашу',
    for_questions: 'Сұрақтар үшін',
    fill_category_name: 'Категория атауын толтырыңыз',
    fill_course_name: 'Курстың атын толтырыңыз',
    fill_category: 'Категория таңдаңыз!',
    fill_teacher: 'Мұғалімді  таңдаңыз!',
    fill_short_desc: 'Курстың қысқаша сипаттамасын толтырыңыз!',
    fill_full_desc: 'Толық курс сипаттамасын толтырыңыз!',
    fill_poster: 'Курстың мұқабасын таңдаңыз!',
    course_update_media_upload: 'Курс сәтті жаңартылды! Күте тұрыңыз, медиа файл жүктелуде!',
    media_uploaded: 'Медиа файл сәтті жүктелді!',
    fill_media: 'Медиа файл таңдаңыз!',
    fill_lesson_name: 'Сабақтың атын толтырыңыз!',
    lesson_created_success: 'Сабақ сәтті құрылды!',
    fill_duration: 'Ұзақтықты толтырыңыз!',
    fill_order: 'Кезекті толтырыңыз!',
    lesson_edited_success: 'Сабақ сәтті жаңартылды!',
    fill_name: 'Атын толтырыңыз!',
    fill_last_name: 'Фамилияны толтырыңыз!',
    fill_phone: 'Телефон нөмерін толтырыңыз!',
    profile_data_update: 'Пайдаланушының жеке деректері жаңартылды!',
    avatar_apdate: 'Аватар жаңартылды!',
    fill_school_name: 'Мектептің атын толтырыңыз!',
    fill_adress_name: 'Мектептің электрондық адресін толтырыңыз!',
    fill_allow_cancel: 'Айына рұқсат етілген бас тарту санын көрсетіңіз!',
    fill_allow_transfer: 'Айына рұқсат етілген аударымдар санын көрсетіңіз!',
    success_school_name: 'Мектеп деректері жаңартылды!',
    fill_email: 'Эл.поштаны толтырыңыз!',
    fill_password: 'Құпиясөзді толтырыңыз!',
    user_created_success: 'Пайдаланушы сәтті құрылды!',
    user_updated_success: 'Пайдаланушы сәтті жаңартылды!',
    user_deleted_success: 'Пайдаланушы сәтті өшірілді!',
    toast_work_schedule: 'Жұмыс кестесі қосылды!',
    toast_work_schedule_edit: 'Жұмыс кестесі жаңартылды!',
    fill_course: 'Курсты таңдаңыз!',
    fill_price: 'Құнын көрсетіңіз!',
    fill_count_lesson: 'Сабақтар санын көрсетіңіз!',
    toast_course_add: 'Курс қосылды!',
    toast_course_edit: 'Пайдаланушы балансының деректері жаңартылды!',
    toast_study_schedule: 'Оқу кестесі қосылды!',
    toast_study_schedule_edit: 'Оқу кестесі жаңартылды!',
    toast_format: 'Сабақ форматын таңдаңыз!',
    toast_type: 'Сабақтың типін таңдаңыз!',
    toast_group_name: 'Топ атын толтырыңыз!',
    toast_students: 'Студентті таңдаңыз!',
    toast_group_create: 'Топ сәтті құрылды!',
    toast_group_update: 'Топ сәтті жаңартылды!',
    toast_section_name: 'Бөлім атын толтырыңыз!',
    toast_section_order: 'Бөлім ретін толтырыңыз!',
    toast_section_add: 'Бөлім қосылды!',
    toast_section_name_edit: 'Бөлім атауы өзгертілді!',
    toast_section_delete: 'Бөлім сәтті жойылды!',
    toast_task_added: 'Тапсырма сәтті қосылды!',
    toast_test_task: 'Тапсырманың атын толтырыңыз!',
    toast_test_task_answer: 'Сұрақ мәтінін толтырыңыз!',
    toast_test_task_options: 'Нұсқа мәтінін толтырыңыз!',
    toast_test_task_options_add: 'Нұсқа қосыңыз!',
    toast_test_task_options_correct: 'Дұрыс нұсқаны таңдаңыз!',
    toast_task_table_r_c: 'Бағандар мен жолдардың санын көрсетіңіз!',
    toast_test_task_text: 'Тапсырманың мәтінін толтырыңыз!',
    toast_task_connect_add: 'Жұп қосыңыз!',
    toast_task_connect_word: 'Сөздерді толтырыңыз!',
    task_updated_success: 'Тапсырма сәтті жаңартылды!',
    something_went_wrong: 'Бірдеңе дұрыс болмады, қайталап көріңіз...',
    toast_notif_add: 'Хабарлама сәтті қосылды!',
    toast_notif_delete: 'Хабарлама сәтті өшірілді!',
    toast_comment_added: 'Пікір сәтті қосылды!',
    toast_comment_field: 'Пікір жолын толтырыңыз!',
    toast_avail_course_delete: 'Курс сәтті өшірілді!',
    toast_avail_course_added: 'Курс сәтті қосылды!',
    toast_lesson_schedule_delete: 'Сабақ кестесі сәтті өшірілді!',
    empty_notif_list: 'Қазіргі уақытта хабарламалар жоқ',
    toast_work_schedule_delete: 'Жұмыс кестесі сәтті өшірілді!',
    toast_lesson_delete: 'Сабақ сәтті өшірілді!',
    toast_teacher_course_remove: 'Мұғалім тізімнен сәтті шығарылды!',
    toast_teacher_course_added: 'Мұғалім тізімге сәтті қосылды!',
    toast_student_course_remove: 'Студент тізімнен сәтті шығарылды!',
    toast_student_course_added: 'Студент тізімге сәтті қосылды!',
    empty_course: 'Қазіргі уақытта курс жоқ.',
    my_group: 'Менің группаларым',
    my_groups_student: 'Менің тобымдағы студенттер',
    search: 'Іздеу',
    no_matches: 'Сәйкестік жоқ',
    kazakh: 'Қазақ',
    rus: 'Орыс',
    eng: 'Ағылшын',
    turk: 'Түрік',
    nem: 'Неміс',
    venger: 'Венгер',
    toast_del_category_success: 'Санат сәтті жойылды!',
    copy: 'Курсты көшіру',
    toast_copy_success: 'Курс сәтті көшірілді!',
    degree_name: 'Дәреже',
    toast_degree_created_success: 'Дәреже сәтті құрылды!',
    toast_degree_deleted_success: 'Дәреже сәтті жойылды!',
    select_degree: 'Дәрежені таңдаңыз',
    select_range_data: 'Күн аралығын таңдаңыз',
    offer: 'Ұсыныс',
    i_have_acc: 'Менде аккаунт бар',
    account_create: 'Аккаунт қосылды',
    registrate: 'Тіркеу',
    italy: 'Италия',
    terms: 'Уақыты',
    del_format: 'Формат өшіру',
    add_format: 'Формат қосу',
    fill_format: 'Форматтың атын толтырыңыз',
    name_format: 'Формат атауы',
    course_format: 'Курстың форматы',
    toast_format_created_success: 'Формат сәтті қосылды!',
    toast_format_deleted_success: 'Формат сәтті өшірілді!',
    format: 'Формат',
    poster_url: 'Youtube-тегі бейнеге сілтеме',
    link_youtube: 'Youtube-тен бейне сілтемесін тіркеңіз',
    deadlines_for_submission: 'Тапсыру мерзімдері',
    poster_media: 'Өз медиа файлыңызды таңдаңыз',
    how_it_works: 'Grafen қалай жұмыс істейді?',
    text_onboard: 'Кішкене жаттығудан өтіп, платформаны оңай және қарапайым пайдаланыңыз!'
  },
  en: {
    course: 'Courses',
    school_course: 'School courses',
    my_course: 'My courses',
    schedule: 'Schedule',
    user: 'Users',
    analytics: 'Analytics',
    catalog: 'Catalog',
    onboard: 'Onboarding',
    info: 'Info',
    admin_school: 'School admin',
    manager: 'Manager',
    teacher: 'Teacher',
    student: 'Student',
    create_course: 'Create a course',
    filter: 'Filter',
    choose_category: 'Choose a category',
    choose_price: 'Choose a price',
    search_by_text: 'Search by text',
    count_of_stud: 'Count of students',
    count_of_lessons: 'Count of lessons',
    students: 'students',
    lessons: 'lessons',
    course_name: 'Course name',
    lecturer: 'Teachers',
    lecture: 'Teacher',
    the_students: 'Students',
    the_studentss: 'Students ',
    lesson: 'Lessons',
    categories: 'Categories',
    category: 'Category',
    actions: 'Actions',
    delete: 'Delete',
    edit: 'Edit',
    view: 'View',
    my_profile: 'My profile',
    exit: 'Exit',
    back: 'Back',
    creating_course: 'Creating a course',
    add_category: 'Add a category',
    del_category: 'Delete a category',
    add_degree: 'Add a degree',
    del_degree: 'Delete a degree',
    main_info: 'Main information',
    choose_lang_course: 'Choose language of course',
    instruction_add_course: 'Video instruction for creating a course',
    course_not: 'No courses have been created.',
    try_add_course: 'It is time to create them!',
    price_of_course: 'Cost of course',
    price_of_study: 'Cost of education',
    free_course: 'Free course',
    category_of_course: 'Category of the course',
    display_the_course: 'Show course in catalog',
    select_teacher: 'Select teacher',
    close: 'Close',
    search_by_teacher: 'Search by teacher...',
    search_by_student: 'Search by student...',
    selected_teachers: 'Selected teachers',
    selected_students: 'Selected students',
    details_of_course: 'Details of course',
    short_course_desc: 'Short description of the course',
    full_course_desc: 'Full description of the course',
    show_catalog_course: 'Appears in the course catalog',
    course_cover: 'Cover of the course',
    this_img_will_be_show_on_course_cover: 'This image will be displayed in the course catalog',
    path_to_file: 'Specify the path to the file or drag it to this area.',
    not_exceed: 'The file must not exceed 30 MB',
    select_file: 'Select a file',
    media_on_page: 'Media on the course page (optional)',
    it_can_be_photo: 'It can be a photo, video, demo lesson or you can attach a link from Youtube',
    course_created_success: 'Course created successfully!',
    category_created_success: 'Category successfully created!',
    course_created_error: 'Error! Check the data!',
    auth: 'Authorization',
    auth_mail: 'Your email',
    auth_password: 'Your password',
    auth_forget_pass: 'Forgot your password?',
    auth_enter: 'Sign in',
    lang_course: 'Language of study',
    title_course: 'Search by text',
    cost_course: 'Price',
    recovery_password: 'Password recovery',
    recovery: 'Recovery',
    mail_on: 'The letter is in your mail!',
    confirm_reset_password: 'Confirm password reset by clicking on the button in the email!',
    go_back: 'Come back',
    fill_fields: 'Please, fill out this field',
    select_all: 'Select all',
    remove_all: 'Remove all',
    category_name: 'Category name',
    about_course: 'About the course',
    lesson_list: 'List of lessons',
    course_analytic: 'Course analytics',
    edit_course: 'Edit the course',
    delete_course: 'Delete the course',
    delete_lesson: 'Delete the lesson',
    categories_of_course: 'Course categories',
    features_of_course: 'Course features',
    sure_remove_entry: 'Are you sure you want to delete this entry?',
    sure_remove_lesson_schedule: 'Are you sure you want to remove this lesson schedule',
    sure_remove_work_schedule: 'Are you sure you want to remove this work schedule',
    sure_remove_course: 'Are you sure you want to remove this course',
    sure_delete_course: 'Are you sure you want to delete this course',
    sure_delete_lesson: 'Are you sure you want to delete this lesson',
    sure_delete_group: 'Are you sure you want to delete this group',
    sure_delete_user: 'Are you sure you want to delete this user',
    agree_del: 'Yes, delete',
    cancel: 'Cancel',
    all_lessons: 'All lessons',
    create_lessons: 'Create a lesson',
    vido_lesson: 'Video lesson',
    test_lesson: 'Test',
    live_lesson: 'Live lesson',
    duration: 'Duration',
    minute: 'min',
    preview: 'Preview',
    no_lesson: 'There is no lesson.',
    add_teacher: 'Add teacher',
    add_student: 'Add student',
    groups: 'Groups',
    courses_teacher: 'Course teachers',
    courses_student: 'Course students',
    fullname: 'First name, Last name',
    mail: 'Email',
    phone: 'Phone',
    courses_group: 'Course groups',
    add_group: 'Create a group',
    name_group: 'Group name',
    edit_group: 'Edit group',
    saturday: 'Saturday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    sunday: 'Sunday',
    select_instructor: 'Select teacher',
    save: 'Save',
    seacrh_teacher: 'Search by teachers',
    select_student: 'Select student',
    search_student: 'Search by students',
    delete_teacher: 'Remove teacher',
    delete_student: 'Remove student',
    delete_group: 'Remove group',
    sure_remove_teacher_from_course: 'Are you sure you want to remove the teacher from the course',
    agree_remove: 'Yes, remove',
    sure_remove_student_from_group: 'Are you sure you want to remove the student from the group',
    create_group: 'Create a group',
    instruction_add_group: 'Video instruction for creating a group',
    group_name: 'Group name',
    group_teacher: 'Group teacher',
    select_students: 'Select students',
    group_type: 'Group type',
    trial_lesson: 'Trial lesson',
    indiv_lesson: 'Individuallesson',
    group_lesson: 'Group lesson',
    pair_lesson: 'Paired lesson',
    groups_schedule: 'Group schedule',
    no_schedule: 'Currently no schedule',
    add_lesson: 'Add lesson',
    select_lesson_format: 'Select lesson format',
    day_of_week: 'Day of week',
    start_time: 'Start time',
    end_time: 'End time',
    amount_of_course: 'Amount of course',
    amount_of_lesson: 'Amount of lesson',
    last_login: 'Last entry (days)',
    add_user: 'Add user',
    role: 'Role',
    action: 'Actions',
    reset_filter: 'Reset filter',
    del_user: 'Delete user',
    video_instruction_create_user: 'Video instruction for creating a user',
    creating_user: 'Creating a user',
    select_role: 'Select role',
    create_user: 'Create user',
    name: 'Name',
    last_name: 'Last name',
    password: 'Password',
    generate: 'Generate',
    email_has_already: 'The mail already exists!',
    change_photo: 'Change photo',
    teachers_courses: 'Teachers courses',
    work_schedule: 'Work schedule',
    add_course: 'Add course',
    add_graph: 'Add graph',
    select_course: 'Select course',
    search_by_course: 'Search by courses',
    remove_course: 'Remove course',
    add_work_schedule: 'Add work schedule',
    add_lesson_schedule: 'Add lesson schedule',
    choose_suit_schedule: 'Choose a suitable schedule',
    start: 'Start',
    end: 'End',
    remove_work_schedule: 'Remove work schedule',
    main: 'Main',
    communications: 'Communication',
    mailing: 'Mailimg',
    comment: 'Comments',
    documents: 'Documents',
    document: 'Document',
    filter_by_date: 'Filter by date:',
    from: 'from',
    to: 'to',
    your_comment: 'Your comment is here',
    add_comments: 'Add comment',
    balance: 'Student balance',
    students_course: 'Student courses',
    payment: 'Payment',
    lessonss: 'lesson(s)',
    add_pay: 'Add a payment',
    show_p_history: 'Show history',
    study_schedule: 'Study schedule',
    remove_lesson_schedule: 'Remove lesson schedule',
    lesson_format: 'Lesson format',
    lesson_type: 'Lesson type',
    select_format_lesson: 'Select lesson format',
    select_type_lesson: 'Select lesson type',
    select_schedule: 'Select schedule',
    add_schedule: 'Add schedule',
    search_payment: 'Search for payment by text',
    payment_history: 'Payment history',
    payment_date: 'Date of payment',
    recommend_teacher: 'Recommended teachers',
    video_instruction_create_lesson: 'Video instruction for creating a lesson',
    create_lesson: 'Create a lesson',
    info_about_lesson: 'Lesson Information',
    constructor: 'Task constructor',
    name_of_lesson: 'Lesson name',
    video_material: 'Video material',
    select_video_display_lesson: 'Select the video to be displayed in the lesson',
    lesson_section: 'Sections of the lesson',
    section_name: 'Section name',
    add_section: 'Add section',
    order: 'Order',
    create: 'Create',
    task_test_type: 'Test task',
    task_media_type: 'Insert a media file',
    task_word_enter_type: 'Insert the words into the text',
    task_word_enter_select_type: 'Insert words with options',
    task_table_enter_type: 'Insert the word in the table',
    task_table_enter_select_type: 'Insert options in the table',
    task_find_error_type: 'Find errors in the text',
    task_text_type: 'Text task',
    task_compare_type: 'Compare the words',
    task_essay_type: 'Write an essay',
    task_file_check_type: 'Student file checking',
    task_file_add_type: 'Add the file to the lesson',
    create_task_lesson: 'Create a task for the lesson',
    task_type: 'Task type',
    answer_options: 'Answer options',
    note_correct_answer: 'Also note the correct answers',
    immediately_show_correct_answer: 'Immediately show the correct answer to the student',
    option: 'Option',
    add_options: 'Add an option',
    save_task: 'Save a task',
    correct_answer: 'This is the right option',
    answer_text: 'The text of the question',
    add_file: 'Add file',
    would_be_ppt_media: 'It can be a presentation or a video lesson',
    recommend_file: 'File recommendations',
    img_format: 'Image formats',
    video_format: 'Video formats',
    audio_format: 'Audio formats',
    file_format: 'File formats',
    if_media_more: 'If the file is larger than',
    mb: 'Мb',
    media_recom: 'Smaller images load and display faster on a computer display',
    usefull_links: 'Useful links:',
    ok: 'Fine',
    img_compression: 'Image compression',
    video_compression: 'Video compression',
    audio_compression: 'Audio compression',
    step_1_2: 'Step 1/2. Task text',
    step_2_2: 'Step 2/2. Highlight the words to be inserted',
    save_text: 'Save text',
    back_to_text: 'Return to text',
    text_your_text: 'Write your text here and then you can add words for gaps',
    add_pair: 'Create pairs',
    create_pair: 'Add a pair',
    field_essay: 'The student will have a field to enter an essay',
    field_load: 'The student will have a field to upload a file',
    pin: 'Pin',
    name_of_task: 'Task name',
    pin_student_file: 'You can attach files for students to download',
    step_1_2_table: 'Step 1/2. Make and complete the table',
    strings: 'Rows',
    columns: 'Columns',
    error_options: 'Error variant',
    add_task: 'Add task',
    mulitmedia: 'Multimedia',
    fill_all_gaps: 'You need to fill in all the gaps with the words that you think are correct',
    essay_text: 'Essay text',
    your_note: 'Your note',
    delete_task: 'Delete task',
    sure_delete_task: 'Are you sure you want to delete this task',
    word_1: 'Word 1',
    word_2: 'Word 2',
    cannot_change: 'You cannot change the number of columns and rows!',
    not_found_query: 'Sorry, no results were found for your search!',
    catalog_empty: 'The catalog is currently empty',
    edit_lesson: 'Editing the lesson',
    my_balance: 'My balance',
    start_lesson: 'Start the lesson',
    start_course: 'Start the course',
    buy_course: 'Buy the course',
    course_of_lang: 'Language of course',
    del_section: 'Delete the section',
    edit_section: 'Edit the section',
    sure_delete_section: 'Are you sure you want to delete the section',
    exercises_empty: 'Currently there is no section for tasks',
    end_lesson: 'Finish the lesson',
    a_lesson: 'Lesson',
    answers: 'Answers',
    sure_finish_lesson: 'Are you sure you want to complete the lesson',
    agree_finish: 'Yes, finish',
    interactive_board: 'Interactive board',
    back_to_tasks: 'Back to tasks',
    edit_work_schedule: 'Edit work schedule',
    reset_answers: 'Reset answers',
    check: 'Check',
    a_course: 'Course',
    clear: 'Clear',
    doesnt_support: 'Your browser does not support this file',
    students_answer: 'Students answers',
    answer_no: 'Students have no answer',
    choose_group: 'Select group',
    empty_payment_history: 'No payment history',
    select_section: 'Select section',
    a_group: 'Group',
    an_action: 'Action',
    write: 'Write to',
    correct: 'correct',
    the_tasks: 'task(s)',
    show_answers: 'Show answers',
    hide_answers: 'Hide answers',
    correct_answers: 'Correct answers',
    students_answers: 'Student answers',
    send_letter: 'Send mail',
    send: 'Send',
    subject_mail: 'Mail subject',
    text_mail: 'Mail text',
    date: 'date',
    recommend: 'Recommended',
    regular_lesson: 'Regular lessons',
    trial_lessons: 'Trial lessons',
    empty_document_list: 'There are no documents at the moment',
    empty_comment_list: 'There are no comments at the moment',
    empty_schedule_list: 'There is no study schedule at the moment',
    edit_lesson_schedule: 'Edit study schedule',
    today: 'Today',
    week: 'week',
    day: 'day',
    my_settings: 'My settings',
    personal_settings: 'Personal settings',
    school_settings: 'School settings',
    newsletter: 'Mailing',
    my_tariff: 'My tariff',
    country: 'A country',
    school_name: 'School name',
    school_desc: 'School description',
    school_adress: 'School address',
    school_currency: 'School currency',
    school_allowed_cancel: 'Number of allowed cancellations per month',
    school_allowed_cancel_for: 'Cancellation allowed for',
    work_time: 'Working hours',
    auto_lesson: 'Automatically conduct lessons of yesterday',
    minute_f: 'minute',
    hour: 'hour',
    a_hour: 'hour',
    school_allowed_transfer: 'Number of allowed transfers per month',
    school_allowed_transfer_for: 'Transfer allowed for',
    hi_test: 'Hello, Test!',
    notif_lesson: 'We remind you that you have a lesson on the course {course name} through {lesson.time_from} with teacher {student name}',
    wish_lesson: 'We wish you to enjoy the lesson',
    if_cant_notif: 'If you do not succeed, you can reschedule or cancel the lesson in your personal account',
    with_scincerly: '– Best regards, {school name} team!',
    notification_lesson: 'Lesson reminder',
    new_mailing: 'New mailing',
    recipients: 'Recipients',
    over_period_time: 'Over a period of time',
    all: 'All',
    text_mailing: 'Mailing text',
    trial: 'Trial',
    indiv: 'Individual',
    group: 'Group',
    pair: 'Doubles',
    change: 'Change',
    add_mailing: 'Add mailing',
    delete_notif: 'Delete reminder',
    sure_notif_delete: 'Are you sure you want to delete the reminder',
    update_tariff: 'Update tariff',
    payment_a_history: 'Payment history',
    active: 'Active',
    no_active: 'Not active',
    monthly_payment: 'Monthly payment',
    number_students: 'Number of students',
    next_payment: 'Next payment',
    number_months: 'Number of months',
    total: 'Total:',
    pay: 'Pay',
    sum: 'Sum',
    transactions: 'Transaction No.',
    description: 'Description',
    add_photo: 'Add photo',
    lesson_done: 'Lesson done',
    reschedule_lesson: 'Reschedule the lesson',
    cancel_lesson: 'Cancel lesson',
    return: 'Return',
    filter_by_schedule: 'Filter by schedule',
    by_lesson: 'By lessons',
    by_students: 'By students',
    empty_analytic: 'At the moment the analytics is empty.',
    empty_analytic_1: 'Start developing your school so that analytics appear',
    graphs: 'Graphs',
    report: 'Report',
    jan: 'JAN',
    feb: 'FEB',
    mar: 'MAR',
    apr: 'APR',
    may: 'MAY',
    jun: 'JUN',
    jul: 'JUL',
    avg: 'AVG',
    sen: 'SEN',
    okt: 'OKT',
    nov: 'NOV',
    dec: 'DEC',
    select_by_course: 'Select by course',
    amount_of_purchase: 'Number of purchases',
    sum_of_sales: 'Sales amount',
    search__student: 'Search a student',
    search__teacher: 'Search a teacher',
    more: 'More',
    purchases: 'Purchases',
    selling: 'Selling',
    done: 'Done',
    progress: 'Progress',
    current_month: 'Current month',
    conducted_lesson: 'Conducted lessons',
    canceled_lesson: 'Canceled lessons',
    transfered_lesson: 'Transferred lessons',
    statistic_type_lesson: 'Statistics by lesson type',
    individual_lesson: 'Individual lessons',
    conducted: 'Conducted',
    canceled: 'Canceled',
    postponed: 'Postponed',
    implementation: 'Implementation',
    select_by_student: 'Select by student',
    a_lessons: 'lesson(s)',
    attendance_of_lessons: 'Attendance of lessons',
    effectiveness_of_lessons: 'Effectiveness of lessons',
    total_lessons: 'Total lessons',
    exercises: 'Exercises',
    a_correct: 'Correct',
    incorrect: 'Wrong',
    correct_done: 'Correct / Done',
    all_right_reserved: 'All rights reserved',
    personal_data: 'Processing of personal data',
    confidentiality: 'Confidentiality',
    terms_of_use: 'Terms of use',
    online_payments_security: 'Online payment security',
    open: 'Open',
    for_questions: 'For questions',
    fill_category_name: 'Fill in the name of the category',
    fill_course_name: 'Fill in the name of the course',
    fill_category: 'Select a category!',
    fill_teacher: 'Select a teacher!',
    fill_short_desc: 'Fill out a short course description!',
    fill_full_desc: 'Fill out the full course description!',
    fill_poster: 'Select course cover!',
    course_update_media_upload: 'The course has been successfully updated! Please wait, the media file is loading!',
    media_uploaded: 'Media file uploaded successfully!',
    fill_media: 'Select media file!',
    fill_lesson_name: 'Fill in the name of the lesson!',
    lesson_created_success: 'Lesson successfully created!',
    fill_duration: 'Fill in the duration!',
    fill_order: 'Fill in the order!',
    lesson_edited_success: 'The lesson has been successfully updated!',
    fill_name: 'Fill in the name!',
    fill_last_name: 'Fill in the last name!',
    fill_phone: 'Fill in the phone!',
    profile_data_update: 'User personal data updated!',
    avatar_apdate: 'Avatar updated!',
    fill_school_name: 'Fill in the name of the school!',
    fill_adress_name: 'Fill in the address of the school!',
    fill_allow_cancel: 'Specify the number of allowed cancellations per month!',
    fill_allow_transfer: 'Specify the number of allowed transfers per month!',
    success_school_name: 'School data has been updated!',
    fill_email: 'Fill in the e-mail!',
    fill_password: 'Fill in the password!',
    user_created_success: 'User successfully was created!',
    user_updated_success: 'User successfully was updated!',
    user_deleted_success: 'User successfully was deleted!',
    toast_work_schedule: 'Work schedule added!',
    toast_work_schedule_edit: 'Work schedule updated!',
    fill_course: 'Select course!',
    fill_price: 'Entry the cost!',
    fill_count_lesson: 'Entry the number of lessons!',
    toast_course_add: 'The course added!',
    toast_course_edit: 'User balance data updated!',
    toast_study_schedule: 'Schedule added!',
    toast_study_schedule_edit: 'Schedule updated!',
    toast_format: 'Select lesson format!',
    toast_type: 'Select lesson type!',
    toast_group_name: 'Fill in the name of the group!',
    toast_students: 'Select student!',
    toast_group_create: 'Group successfully created!',
    toast_group_update: 'Group successfully updated!',
    toast_section_name: 'Fill in the name of the section!',
    toast_section_order: 'Fill in the order of the section!',
    toast_section_add: 'Section added!',
    toast_section_name_edit: 'Section name changed!',
    toast_section_delete: 'Section successfully deleted!',
    toast_task_added: 'Task added successfully!',
    toast_test_task: 'Fill in the task name!',
    toast_test_task_answer: 'Fill in the text of the question!',
    toast_test_task_options: 'Fill in the options text!',
    toast_test_task_options_add: 'Add an option!',
    toast_test_task_options_correct: 'Select the correct option!',
    toast_task_table_r_c: 'Entry the number of columns and rows!',
    toast_test_task_text: 'Fill in the text of the task!',
    toast_task_connect_add: 'Add a pair!',
    toast_task_connect_word: 'Fill in the words!',
    task_updated_success: 'The task successfully updated!',
    something_went_wrong: 'Something went wrong, please try again...',
    toast_notif_add: 'Mailing list successfully added!',
    toast_notif_delete: 'Mailing list successfully deleted!',
    toast_comment_added: 'Comment added successfully!',
    toast_comment_field: 'Fill in the comment field!',
    toast_avail_course_delete: 'The course successfully deleted!',
    toast_avail_course_added: 'The course successfully added!',
    toast_lesson_schedule_delete: 'The study schedule successfully deleted!',
    empty_notif_list: 'There are no notifications at the moment',
    toast_work_schedule_delete: 'The work schedule successfully deleted!',
    toast_lesson_delete: 'The lesson successfully deleted!',
    toast_teacher_course_remove: 'The teacher was successfully removed from the list!',
    toast_teacher_course_added: 'The teacher was successfully added to the list!',
    toast_student_course_remove: 'The student was successfully removed from the list!',
    toast_student_course_added: 'The student was successfully added to the list!',
    empty_course: 'No course at the moment.',
    my_group: 'My groups',
    my_groups_student: 'Students in my group',
    search: 'Search',
    no_matches: 'No matches',
    kazakh: 'Qazaq',
    rus: 'Russian',
    eng: 'English',
    turk: 'Turkish',
    nem: 'German',
    venger: 'Hungarian',
    toast_del_category_success: 'Category deleted successfully!',
    copy: 'Copy the course',
    toast_copy_success: 'Course copied successfully!',
    degree_name: 'A degree',
    toast_degree_created_success: 'Degree successfully created!',
    toast_degree_deleted_success: 'Degree successfully deleted!',
    select_degree: 'Select a degree',
    select_range_data: 'Select date range',
    deadlines_for_submission: 'Deadlines for submission',
    offer: 'Offer',
    i_have_acc: 'I have an account',
    account_create: 'Account created',
    registrate: 'Sign up',
    italy: 'Italian',
    terms: 'Terms',
    del_format: 'Delete a format',
    add_format: 'Add a format',
    fill_format: 'Fill in the name of the format',
    name_format: 'Format name',
    course_format: 'Format of the course',
    toast_format_created_success: 'Format created successfully!',
    toast_format_deleted_success: 'Format deleted successfully!',
    format: 'Format',
    poster_url: 'Link to video in Youtube',
    link_youtube: 'Attach video link from Youtube',
    poster_media: 'Choose your media file',
    how_it_works: 'How Graphen works?',
    text_onboard: 'Take a little training and use the platform easily and simply!'
  }
}
