<template>
  <div class="navbar">
    <div class="menu medium_s" @click="closeMenu">
      <img src="/icons/Close=Filled.svg" alt="close">
      <p class="menu_close">{{$t('close')}}</p>
    </div>
    <div class="navbar_block flex bckg_white border_gray_2" style="flex-direction: column-reverse">
      <button
        @click="openModal"
        :class="[selected === 8 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" style="margin-top: 50px;">
        <img src="/icons/Info=Outline.svg" alt="info" class="fa">
        <img src="/icons/Info=Filled.svg" alt="info" class="fa">
        <p>{{$t('info')}}</p>
      </button>
      <!-- <router-link to="/"
        @click.native="selectLink(7)"
        :class="[selected === 7 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" style="margin-top: 50px;">
        <img src="/icons/Rock=Outlined.svg" alt="info" class="fa">
        <img src="/icons/Onboard=Filled.svg" alt="info" class="fa">
        <p>{{$t('onboard')}}</p>
      </router-link> -->
      <router-link to="/catalog"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(6)"
        :class="[selected === 6 ? 'selected_link' : '']">
        <img src="/icons/Catalog=Outline.svg" alt="catalog" class="fa">
        <img src="/icons/Catalog=Filled.svg" alt="catalog" class="fa">
        <p>{{$t('catalog')}}</p>
      </router-link>
      <router-link to="/analytics"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(5)"
        :class="[selected === 5 ? 'selected_link' : '']">
        <img src="/icons/Analytics=Outline.svg" alt="analytics" class="fa">
        <img src="/icons/Analytics=Filled.svg" alt="analytics" class="fa">
        <p>{{$t('analytics')}}</p>
      </router-link>
      <router-link to="/users-list"
        v-if="uData.rle === 'admin' || uData.rle === 'admin_school'"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(4)"
        :class="[selected === 4 ? 'selected_link' : '']">
        <img src="/icons/User=Outline.svg" alt="user" class="fa">
        <img src="/icons/User=Filled.svg" alt="user" class="fa">
        <p>{{$t('user')}}</p>
      </router-link>
      <router-link to="/schedule"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(3)"
        :class="[selected === 3 ? 'selected_link' : '']">
        <img src="/icons/Timetable=Outline.svg" alt="timetable" class="fa">
        <img src="/icons/Timetable=Filled.svg" alt="timetable" class="fa">
        <p>{{$t('schedule')}}</p>
      </router-link>
      <div class="inner" v-if="uData.rle === 'admin' || uData.rle === 'admin_school'">
        <router-link to="/"
          @click.native="selectLink(2)"
          :class="[selected === 2 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('school_course')}}</p>
        </router-link>
        <router-link to="/my-course"
          @click.native="selectLink(1)"
          :class="[selected === 1 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="my_course" class="circle_icon">
          <p>{{$t('my_course')}}</p>
        </router-link>
      </div>
      <div
        v-if="uData.rle === 'admin' || uData.rle === 'admin_school'"
        class="navbar_block_list flex outter main_list_height cocourse_reverseurse"
        :class="[(selected === 2 || selected === 1) ? 'selected_link' : '']">
        <img src="/icons/Course=Outline.svg" alt="course" class="fa c1">
        <img src="/icons/Course=Filled.svg" alt="course" class="fa c2">
        <p>{{$t('course')}}</p>
      </div>
      <router-link to="/my-course"
        v-else
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(1)"
        :class="[selected === 1 ? 'selected_link' : '']">
        <img src="/icons/Course=Outline.svg" alt="user" class="fa">
        <img src="/icons/Course=Filled.svg" alt="user" class="fa">
        <p>{{$t('my_course')}}</p>
      </router-link>
    </div>
    <div>
      <ModalComponent
        v-if="isModalOpen"
        @close-modal="closeModal()">
        <template v-slot:head>
          {{$t('info')}}
        </template>
        <template v-slot:body>
          <InfoPageM />
        </template>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import ModalComponent from '@/components/modal/ModalComponent'
import InfoPageM from '../views/info/InfoPageM.vue'
export default {
  name: 'NavbarPage',
  props: {
    isOpen: Boolean,
    isProfile: Boolean
  },
  components: {
    ModalComponent,
    InfoPageM
  },
  data () {
    return {
      selected: Number(localStorage.getItem('nb')),
      isModalOpen: false,
      prevSelected: '',
      uData: JSON.parse(localStorage.getItem('uData'))
    }
  },
  watch: {
    isProfile: function () {
      this.selectProfile()
    }
  },
  methods: {
    selectLink: function (ind) {
      localStorage.setItem('nb', ind)
      this.selected = Number(localStorage.getItem('nb'))
      this.closeMenu()
    },
    selectProfile: function () {
      this.selected = Number(localStorage.getItem('nb'))
    },
    closeMenu: function () {
      this.$emit('hide-or-show', false)
    },
    // modal
    closeModal: function () {
      this.isModalOpen = false
      this.selected = Number(localStorage.getItem('nb'))
    },
    openModal: function () {
      this.isModalOpen = true
      this.selected = 8
    }
  }
}
</script>
