<template>
  <div>
    <ForMainLayout
      @hide-or-show="menuOpen"
      @my-profile="toMyProfile"
      :isOpen="isOpen"/>
    <div class="flex">
      <Navbar
        class="navbar_visible"
        :isProfile="isProfile"/>
      <OpenedNavbar
        class="menu_invisible"
        ref="menu"
        v-if="isOpen"
        @hide-or-show="menuOpen"/>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import ForMainLayout from '@/components/ForMainLayout'
import OpenedNavbar from '@/components/OpenedNavbar'

export default {
  name: 'MainLayout',
  components: { Navbar, ForMainLayout, OpenedNavbar },
  data () {
    return {
      uData: JSON.parse(localStorage.getItem('uData')),
      isOpen: false,
      isProfile: false,
      langInd: ['ru', 'kz', 'en']
    }
  },
  methods: {
    checkAccessDate: function () {
      const date = new Date().toISOString().slice(0, 10)
      if (this.uData.tm_in !== date) {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
      }
    },
    checkIsActiveSchool: function () {
      this.uData.tf_st === true ? this.isTariffActive = true : this.isTariffActive = false
      if (this.isTariffActive === false) {
        this.$router.push('/guest-catalog').catch(() => { })
      }
    },
    localLanguage: function () {
      this.$i18n.locale = this.langInd[localStorage.getItem('lang')]
    },
    menuOpen: function (item) {
      this.isOpen = item
      const menu = this.$refs.menu
      if (this.isOpen) {
        menu.classList.add('menu_visible')
        menu.classList.remove('menu_invisible')
      } else {
        menu.classList.remove('menu_visible')
        menu.classList.add('menu_invisible')
      }
    },
    toMyProfile: function () {
      this.isProfile = !this.isProfile
    }
  },
  mounted () {
    this.localLanguage()
    this.checkAccessDate()
    // this.checkIsActiveSchool()
  }
}
</script>
